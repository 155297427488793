<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Designation</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 sm3 xs12 pt-4>
                <v-text-field
                  class="rounded-xl"
                  outlined
                  dense
                  placeholder="Search"
                  v-model="searchKeyword"
                >
                </v-text-field>
              </v-flex>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="40%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Designation
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      align-self-center
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Designation</span
                      >
                    </v-flex>
                    <v-flex xs12 align-self-center text-left pt-8 pa-2 px-5>
                      <v-text-field
                        v-model="position"
                        label="Designation"
                     
                        required
                        outlined
                        hide-details
                        dense
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-select
                        v-model="roleName"
                        :items="roleList"
                        item-text="roles"
                        item-value="_id"
                        :rules="[rules.required]"
                        hide-details
                        outlined
                        dense
                        label="Choose Role"
                      >
                      </v-select>
                    </v-flex> -->
                    <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-select
                        v-model="type"
                        :items="typeList"
                        item-text="name"
                        item-value="name"
                        :rules="[rules.required]"
                        hide-details
                        outlined
                        dense
                        label="Choose Type"
                      >
                      </v-select>
                    </v-flex> -->

                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-2
                      px-5
                      v-if="type == 'Department'"
                    >
                      <v-select
                        v-model="department"
                        :items="departmentList"
                        item-text="deptName"
                        item-value="_id"
                        hide-details
                        outlined
                        dense
                        label="Choose Department"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex
                      xs12
                      align-self-center
                      text-left
                      pa-2
                      px-5
                      v-if="type == 'Project'"
                    >
                      <v-select
                        v-model="project"
                        :items="projectList"
                        item-text="projectName"
                        item-value="_id"
                        hide-details
                        outlined
                        dense
                        label="Choose Project"
                      >
                      </v-select>
                    </v-flex>

                    <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                      <v-layout wrap>
                        <v-flex xs10 align-self-center text-left>
                          <span class="kumbhMedium">
                            Does this position belong to only one user?
                          </span>
                        </v-flex>
                        <v-flex xs2 align-self-center text-left>
                          <v-btn
                            tile
                            color="#005f32"
                            large
                            :ripple="false"
                            plain
                            depressed
                          >
                            <v-switch
                              hide-details
                              color="#005f32"
                              v-model="isExclusive"
                              label=""
                            ></v-switch>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="error"
                      tile
                      outlined
                      @click="dialogclose(), (roleName = null)"
                    >
                      Close
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      :disabled="!addcat"
                      @click="addPosition()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in positionList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile color="#F5F5DC">
              <v-layout wrap justify-end py-4>
                <v-flex xs12 align-self-start text-center  px-3 pt-2 >
                  <span class="lheight" style="font-family: kumbhBold">{{ item.name }}</span> <br/>
                </v-flex>
                 <v-flex xs12 align-self-start text-left px-3 v-if="item.type == 'Project'" class="showLine2">
                   <span class="showLine2" style="font-family: kumbhMedium">Project  :{{item.project.projectName}}</span> 
                </v-flex>
                 <v-flex xs12 align-self-start text-left px-3 v-if="item.type == 'Project'" >
                  <span class="showLine2" style="font-family: kumbhMedium;">Role  :{{item.role.roles}}</span> 
                </v-flex>
                <v-flex xs6 sm4 md8 align-self-center text-left px-2>
                  <v-dialog persistent v-model="item.menu" max-width="70%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        tile
                        outlined
                        small
                        plain
                        @click="getPosition(item._id)"
                        class="text-capitalize"
                      >
                        <span class="kumbhEBold">Menu management</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-5
                          style="background: #005f32"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Menu Management - {{ item.name }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pa-5 align-self-center v-if="menuData">
                          <v-layout wrap v-if="menuData">
                            <v-flex xs12 pa-2 align-self-center text-center>
                              <span style="font-size:20px;border-bottom:1px solid #000;" class="kumbhBold">
                                Select/deselect menus
                              </span>
                            </v-flex>
                            <v-flex
                              xs12
                              align-self-center
                              pa-2
                              v-if="menuData.length > 0"
                            >
                              <v-container
                                v-for="(item, i) in menuData"
                                :key="i"
                              >
                                <v-layout wrap justify-center>
                                  <v-flex xs12 sm10 align-self-center>
                                    <v-checkbox
                                      v-model="item.isChecked"
                                      :label="item.name"
                                      color="#005f32"
                                      hide-details
                                      @click="reCheck(item, item.isChecked)"
                                    ></v-checkbox>
                                  </v-flex>
                                </v-layout>
                                <v-container v-if="item.isChecked">
                                  <v-layout
                                    wrap
                                    justify-center
                                    v-for="(sub, j) in item.submenus"
                                    :key="j"
                                  >
                                    <v-flex xs12 sm10 px-4 align-self-center>
                                      <v-checkbox
                                        v-model="sub.isChecked"
                                        :label="sub.name"
                                        color="#005f32"
                                        hide-details
                                      ></v-checkbox>
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 align-self-center text-center v-else pa-5>
                          <span class="kumbhRegular">No menus found!</span>
                        </v-flex>
                      </v-layout>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.menu = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#005f32"
                          outlined
                          tile
                          @click="updateMenu()"
                          >Save menu</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>

                <v-flex xs6 sm4 md2 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhBold"
                        class="mr-2"
                        fab
                        outlined
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="#005f32"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #005f32"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this
                        Designation?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#005f32"
                          outlined
                          tile
                          @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs6 sm4 md2 pa-2>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="Edit"
                    depressed
                    fab
                    @click="editcat(item)"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="40%">
            <v-card tile>
              <v-layout wrap>
                <v-flex
                  xs12
                  pa-4
                  text-left
                  align-self-center
                  style="background: #005f32 !important"
                >
                  <span
                    class="kumbhBold"
                    style="color: #ffffff; font-size: 20px"
                    >Edit Designation</span
                  >
                </v-flex>
                <v-flex xs12 pa-5 pt-8 text-left>
                  <v-text-field
                    v-model="editingitem.name"
                    label="Designation"
                
                    outlined
                    hide-details
                    dense
                    required
                  >
                  </v-text-field>
                </v-flex>

                <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-select
                    v-model="editingitem.role"
                    :items="roleList"
                    item-text="roles"
                    item-value="_id"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                    label="Choose Role"
                  >
                  </v-select>
                </v-flex> -->
                <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-select
                    v-model="editingitem.type"
                    :items="typeList"
                    item-text="name"
                    item-value="name"
                    hide-details
                    outlined
                    readonly
                    title="You cannot edit this field"
                    dense
                    label="Choose Type"
                  >
                  </v-select>
                </v-flex> -->

                <!-- <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-2
                  px-5
                  v-if="editingitem.type == 'Department'"
                >
                  <v-select
                    v-model="editingitem.department"
                    :items="departmentList"
                    item-text="deptName"
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                    label="Choose Department"
                  >
                  </v-select>
                </v-flex> -->
                <!-- <v-flex
                  xs12
                  align-self-center
                  text-left
                  pa-2
                  px-5
                  v-if="editingitem.type == 'Project'"
                >
                  <v-select
                    v-model="editingitem.project"
                    :items="projectList"
                    item-text="projectName"
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                    label="Choose Project"
                  >
                  </v-select>
                </v-flex> -->
                <!-- <v-flex xs12 align-self-center text-left pa-2 px-5>
                  <v-layout wrap>
                    <v-flex xs10 align-self-center text-left>
                      <span class="kumbhMedium">
                        Does this position belong to only one user?
                      </span>
                    </v-flex>
                    <v-flex xs2 align-self-center text-left>
                      <v-btn
                        tile
                        color="#005f32"
                        large
                        :ripple="false" 
                        plain
                        depressed
                      >
                        <v-switch
                          hide-details
                          color="#005f32"
                          v-model="editingitem.isExclusive"
                          label=""
                        ></v-switch>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  tile
                  outlined
                  @click="(editdialog = false), getData()"
                >
                  Close
                </v-btn>
                &nbsp;
                <v-btn
                  v-if="!g"
                  color="#005f32"
                  dark
                  tile
                  @click="edit(editingitem._id)"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      searchKeyword:"",
      rules: {
        required: (value) => !!value || "Required.",
      },
      roleList: [],
      typeList: [
        {
          name: "Department",
        },
        {
          name: "Project",
        },
      ],
      departmentList: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      roleName: null,
      position: null,
      type: null,
      department: null,
      project: null,
      projectList: null,
      positionList: null,
      isExclusive: false,
      menuData: null,
      positionId:null,
    };
  },
  watch:{
    searchKeyword(){
      this.getData()
    }
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getRoles();
    this.getDept();
    this.getProject();
    this.getData();
  },

  methods: {
    reCheck(item, check) {
      if (!check) {
        for (let i = 0; i < item.submenus.length; i++) {
          item.submenus[i].isChecked = false;
        }
      }
    },
    updateMenu() {
      this.appLoading = true;
      var data = {};
      data["positionid"] = this.positionId;
      data["menus"] = this.menuData;
      axios({
        url: "/add/edit/positionmenu",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true; 
            this.menuData = null  
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPosition(id) {
      this.positionId=id
      this.menuData=null
      this.appLoading = true;
      axios({
        url: "/get/position",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.menuData = response.data.menus;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRoles() {
      this.appLoading = true;
      axios({
        url: "/getalllist/roles",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.roleList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.departmentList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProject() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projectList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/admin/remove/position",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/getalllist/position",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params:{searchKeyword:this.searchKeyword}
      })
        .then((response) => {
          this.appLoading = false;
          this.positionList = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addPosition() {
      var data = {};
      data["name"] = this.position;
      data["role"] = this.roleName;
      data["isExclusive"] = this.isExclusive;
      data["type"] = this.type;
      if (this.type == "Department") {
        data["department"] = this.department;
        this.project = null;
      }
      if (this.type == "Project") {
        data["project"] = this.project;
        this.department = null;
      }
      axios({
        url: "/add/edit/position",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.roleName = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["role"] = this.editingitem.role;
      data["name"] = this.editingitem.name;
      data["type"] = this.editingitem.type;
      if (this.editingitem.type == "Department") {
        data["department"] = this.editingitem.department;
        this.editingitem.project = null;
      }
      if (this.editingitem.type == "Project") {
        data["project"] = this.editingitem.project;
        this.editingitem.department = null;
      }

      axios({
        url: "/add/edit/position",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.showLine2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
